import { useEffect, useRef, VideoHTMLAttributes } from "react";
import { userVideoCdnBaseUrl } from "../constants";
import { useOnScreen } from "@/helpers/hooks/use-on-screen";

interface TemplateVideoProps {
  videoId: string;

  /**
   * whether the compressed mp4 will be requested. This is mainly used when video viewport is small
   * such as in a video carousel
   */
  showCompressed: boolean;

  /**
   * whether the video poster will be requested immediately on load or when the video is visible on screen
   *
   * @default - true
   */
  lazyLoadPoster?: boolean;
}

export function TemplateVideo(props: VideoHTMLAttributes<HTMLVideoElement> & TemplateVideoProps) {
  const { videoId, showCompressed, lazyLoadPoster = true, ...rest } = props;

  const { ref, setRef: videoRef, isVisible: isOnScreen } = useOnScreen<HTMLVideoElement>(0.1);

  const posterUrl = `${userVideoCdnBaseUrl}/${videoId}/preview.png`;

  useEffect(() => {
    if (!ref) return;
    if (props.muted) {
      ref.defaultMuted = true;
    }
  }, [videoRef]);

  useEffect(() => {
    if (!ref) return;
    if (!lazyLoadPoster) return;

    if (isOnScreen) {
      ref.poster = posterUrl;
    }
  }, [isOnScreen]);

  useEffect(() => {
    if (!ref) return;
    ref.load();
  }, [videoId]);

  return (
    <>
      {!lazyLoadPoster && (
        <link rel="preload" fetchPriority="high" as="image" href={posterUrl} type="image/png" />
      )}
      <video {...rest} ref={videoRef} poster={lazyLoadPoster ? undefined : posterUrl}>
        {isOnScreen && (
          <>
            {showCompressed && (
              <source
                src={`${userVideoCdnBaseUrl}/${props.videoId}/video-compressed.mp4`}
                type="video/mp4"
              />
            )}
            <source src={`${userVideoCdnBaseUrl}/${props.videoId}/video.mp4`} type="video/mp4" />
          </>
        )}
      </video>
    </>
  );
}
