"use client";

import { FireIcon } from "@heroicons/react/20/solid";
import { TemplateVideo } from "./template-video";
import { TemplateData } from "./templates-action";
import { trackEvent } from "@/shared/analytics-wrapper";
import { formatNumber } from "@/shared/formatters";
import { projectTypeMetadata } from "@/shared/project-type-metadata";

export function TemplateTile(
  props: TemplateData & {
    onClick?: () => void;
    ignoreVideoAspectRatio?: boolean;
    videoHeight?: number;
  }
) {
  const handleClick = () => {
    trackEvent({
      eventName: "templateModal",
      action: "click",
      category: props.id,
    });
    props.onClick?.();
  };

  const Icon = projectTypeMetadata[props.type].Icon;

  return (
    <div
      className="w-full cursor-pointer space-y-2 rounded-lg"
      style={props.ignoreVideoAspectRatio ? {} : { aspectRatio: `${props.width}/${props.height}` }}
      onClick={handleClick}
    >
      <div className="relative">
        <TemplateVideo
          showCompressed={true}
          videoId={`${props.id}`}
          preload="none"
          playsInline
          muted
          style={props.videoHeight ? { height: props.videoHeight } : {}}
          className="h-full w-full rounded-lg object-cover transition duration-300 ease-in-out"
          onMouseOver={(e) => {
            const video = e.currentTarget;
            if (video?.paused) video.play();
          }}
          onMouseLeave={(e) => {
            const video = e.currentTarget;
            if (video) {
              try {
                video.pause();
              } catch (e) {}
              video.currentTime = 0;
            }
          }}
        />
        <div className="absolute bottom-1 right-1 flex items-center gap-1 rounded-lg bg-black/80 p-1 text-xs">
          <FireIcon className="h-4 w-4 text-white" /> {formatNumber(props.usedCount)}
        </div>
        <div className="absolute bottom-1 left-1 flex items-center gap-1 rounded-lg bg-black/80 p-1 text-xs">
          <Icon className="h-4 w-4 text-white" />
        </div>
      </div>

      <div className="text-sm">{props.name}</div>
    </div>
  );
}
