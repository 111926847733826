"use client";

import { useEffect, useRef, VideoHTMLAttributes } from "react";
import { webAssetsCdnBaseUrl } from "@/shared/constants";

interface VideoComponentProps extends VideoHTMLAttributes<HTMLVideoElement> {
  assetPath: string;
  baseUrl?: string;
}

export function VideoComponent(props: VideoComponentProps) {
  const { assetPath, baseUrl = webAssetsCdnBaseUrl, ...rest } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (!videoRef.current) return;
    if (props.muted) {
      videoRef.current.defaultMuted = true;
    }
  }, [videoRef]);

  return (
    <video {...rest} poster={`${baseUrl}/${assetPath}.webp`} ref={videoRef}>
      <source src={`${baseUrl}/${assetPath}.webm`} type="video/webm" />
      <source src={`${baseUrl}/${assetPath}.mp4`} type="video/mp4" />
    </video>
  );
}
